import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { RouteComponentProps } from '@reach/router';
import { PageId } from '../modules/routing';

const NotFoundPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <SEO title={'Page Not found'} />
      <div
        id={PageId.NOT_FOUND}
        className={'px-24 py-12 flex flex-col justify-center items-center'}
      >
        <h2>NOT FOUND</h2>
        <p>You just hit a route that doesn&apos;t exist... the sadness.</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
